$(document).ready(function(){
	isi();
	referencer();
	hcpHeaderMenu();
	mobileMenu();
	faqs();
	active();
	$('.match').matchHeight();
	$('.match-header').matchHeight();
	$('.match-title').matchHeight();
	xpand();
	// interstitial();
	transToggle();
	sample();
	b2t();
});


function inputsMatch(in1, in2) {
	console.log('matching')
	var input1 = $(in1);
	var input2 = $(in2);
	if (input1.val() === input2.val()) {
	  // Clear the error message if the inputs match
	  input2.get(0).setCustomValidity("");
	  return true;
	} else {
	  // Set a custom error message if the inputs don't match
	  input2.get(0).setCustomValidity("Inputs do not match.");
	  // Trigger standard HTML validation
	  input2.get(0).reportValidity();
	  return false;
	}
  }

function formatPhoneNumber(value) {
	if (!value) return value;
	const phoneNumber = value.replace(/[^\d]/g, '');
	const phoneNumberLength = phoneNumber.length;
	if (phoneNumberLength < 4) return phoneNumber;
	if (phoneNumberLength < 7) {
	  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
	}
	return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
	  3,
	  6
	)}-${phoneNumber.slice(6, 9)}`;
  }

  function phoneNumberFormatter() {
	const inputField = document.getElementById('phone');
	const formattedInputValue = formatPhoneNumber(inputField.value);
	inputField.value = formattedInputValue;
  }


var hello = function hello(msg, value) {

	// var template = "<section class='helloBar'><div class='grid-container'><div class='grid-x grid-margin-x'><div class='cell small-11'><p>" + msg + "</p></div><div class='cell small-1 text-center'><a href='#' class='goodbye'>X</a></div></div></div></section>";

	$('body').prepend(template).ready(function() {
		animateNav();
	});

	function animateNav() {
		var hbHeight = $('.helloBar').outerHeight();
		$('body').animate({
			top: hbHeight
		}, 1000);
	}

	$(window).resize(function() {
		setTimeout(function() {
			animateNav();
		}, 400);

	});

	$('.goodbye').click(function(e) {
		e.preventDefault();
		$('.helloBar').fadeOut(1000);
		$('.helloBar').remove();
		$('body').animate({
			top: 0
		}, 1000);

		sessionStorage.setItem('hellothere', '1');
	});
};

var getStorage = sessionStorage.getItem('hellothere');
var getUrl = window.location.pathname;

const repForm = () => {
	let form = $('#repForm');

	form.on('change', function(){
		if ($('#repForm').is(':invalid') != 0) {
			$('.repSubmit').attr('disabled', true)
		} else {
			$('.repSubmit').attr('disabled', false)
		}
	});

	form.on('submit', function(e){
		e.preventDefault();
		let url = '/rep';
		var formData = {};
		$(form).find("input[name], select").each(function (index, node) {
			if (node.type === "checkbox") {
				if (node.checked) {
					formData[node.name] = node.value;
				}
			}
			else {
			formData[node.name] = node.value;

		}


		});
		$.post(url, formData).done(function (data) {
			url = "/hcp/request-a-rep/thank-you";
			$(location).attr("href", url);
		});

	
	});
}
repForm();

const b2t = () => {
	let el = $('.b2t');
	let btn = $('.b2tbtn');

	$(document).scroll(function(){
		let w = $(this).scrollTop();
		if(w > 300){
			el.fadeIn();
		} else {
			el.fadeOut();
		}
	});

	$('.b2tbtn').click(function(e){
		e.preventDefault();
		$("html, body").animate({ 
			scrollTop: 0 
		}, "slow");
	});
}

const transToggle = () => {
	$('.transToggle').click(function(e){
		e.preventDefault();
		$('.transcript').stop().slideToggle();
		$(this).parent().find('.arrow').toggleClass('active');
	});

	$('.videoToggle').click(function(e){
		e.preventDefault();
		let title = $(this).data('title');
		let text = $(this).data('text');
		let video = $(this).data('video');
		let trans = $(this).data('trans');

		$('.transcript').html(trans);
		$('.videoTitle').html(title);
		$('.videoText').html(text);
		$('.video').html(video);
	});
}

const modalMaker = (template) => {
		let screen = '<div class="screen"></div>';
		$('body').append(screen);
		$('body').append(template);
	}
const modalRemover = () => {
		$('.hcp-modal').remove();
		$('.screen').remove();
	}

const hcpModal = () => {
		let check = Cookies.get('hcp');
		let optOut = sessionStorage.getItem('optOut');
		// pass cookie warning template
		let template = 
		'<div class="hcp-modal"><a href="/" class="close mtDecline">X</a><h2 class="dk-teal">Are you a US <br> healthcare professional?</h2><p>The information contained in this section of the site is intended for US healthcare professionals only.<br></p><p style="padding-top:10px;">Click Yes if you are a US healthcare professional.<br></p><br><a href="#" class="mtAccept button-yellow">Yes</a></div>';

		if(check == "1" || optOut == "1") {

		} else {
			modalMaker(template);
		}

		$('.mtAccept').click(function(e){
			e.preventDefault();
			modalRemover();
			Cookies.set('hcp', '1');
		});
		$('.mtDecline').click(function(e){
		});
	}

	var url = window.location.pathname.split('/');
	if (url[1] === "hcp") {
		hcpModal();
	}




// const interstitial = () => {
// 	let trigger = $('.hcpPop');
// 	trigger.click(function(e){
// 		e.preventDefault();
// 		let template = '<div class="screen"></div><div class="hcp-modal"><div class="close">X</div><h2 class="dk-teal">Are you a US <br> healthcare professional?</h2><p>The information contained in this section of the site is intended for US healthcare professionals only.<br></p><p style="padding-top:10px;">Click Yes if you are a US healthcare professional.<br></p><br><a href="/hcp" class="button-yellow okay">Yes</a></div>';
// 		$('body').append(template);
// 		$('.okay').click(function(){
// 			$('.screen').remove();
// 			$('.hcp-modal').remove();
// 		});
// 		$('.screen').click(function(){
// 			$(this).remove();
// 			$('.hcp-modal').remove();
// 		});
// 		$('.close').click(function(){
// 			$('.screen').remove();
// 			$('.hcp-modal').remove();
// 		});
// 	});
// }

const sample = () => {
	let trigger = $('.sample');
	trigger.click(function(e){
		e.preventDefault();
		let template = '<div class="screen"></div><div class="hcp-modal"><div class="close">X</div><h2 class="dk-teal"><h3>Thank you for visiting SYMPROIC.com</h3><p>You are now leaving SYMPROIC.com and visiting a third-party website that is owned and operated by an independent party not controlled by Collegium. Collegium is not responsible for the content or accuracy of information contained on other parties’ sites or their privacy practices.</p><br><p>Do you wish to continue?</p><br><a href="https://mysamplecloset.com/bdsi" target="_blank" class="button-yellow okay">Yes</a></div>';
		$('body').append(template);
		$('.okay').click(function(){
			$('.screen').remove();
			$('.hcp-modal').remove();
		});
		$('.screen').click(function(){
			$(this).remove();
			$('.hcp-modal').remove();
		});
		$('.close').click(function(){
			$('.screen').remove();
			$('.hcp-modal').remove();
		});
	});
}

const xpand = () => {
	let expand = $('.xpand a');
	let arrow = $('.xarrow');
	arrow.click(function(e){
		e.preventDefault();
		let text = expand.text();
		expand.text(text == "Expand All" ? "Collapse All" : "Expand All").toggleClass('collapser');
		$(this).toggleClass('active');
		$('.a').each(function(){
			if(!expand.hasClass('collapser')){
				$(this).stop().hide();
				$(this).parent().find('.arrow').removeClass('active');
			} else {
				$(this).stop().show();
				$(this).parent().find('.arrow').addClass('active');
			}
		});
	});
	expand.click(function(e){
		e.preventDefault();
		let text = $(this).text();
		$(this).text(text == "Expand All" ? "Collapse All" : "Expand All").toggleClass('collapser');
		$(this).parent().parent().find('.arrow').toggleClass('active');
		$('.a').each(function(){
			if(!expand.hasClass('collapser')){
				$(this).stop().hide();
				$(this).parent().find('.arrow').removeClass('active');
			} else {
				$(this).stop().show();
				$(this).parent().find('.arrow').addClass('active');
			}
		});
	});
}

const active = () => {
	let url = window.location.pathname;
	$('.navigation a').each(function(){
		let link = $(this).attr('href');
		if(link === url){
			$(this).addClass('active');
		}
	});
}

const faqs = () => {
	$('.q').click(function(){
		let pre = $(this).parent().find('.a');
		if(pre.hasClass('expanded')){
			pre.removeClass('expanded');
			pre.addClass('collapsed');
			pre.stop().hide();
			$(this).parent().find('.arrow').removeClass('active');
		} else {
			pre.addClass('expanded');
			pre.removeClass('collapsed')
			pre.stop().slideToggle();
			$(this).parent().find('.arrow').addClass('active');
		}
		
		
	});
}

const isi = () => {
	let $windowHeight = $(window).height();
	let homeBase = $('#isi').offset().top;
	$(window).scroll(function(){
		if(($(window).scrollTop() + $windowHeight) > homeBase){
			$("#isi-float").stop().removeClass('slideOutDown').addClass('slideOutDown');
		} else {
			$("#isi-float").stop().removeClass('slideOutDown').addClass('slideInUp');
		}
	});
}

const referencer = () => {
	let count = 0;
	$('.references li').each(function(){
		count++;
		let each = $(this).data('refs');
		let template = '<strong>' + count + '.</strong> ' + each + " ";
		$('.refBlock').append(template); 
	});
	if(count > 1){
		$('.refBlock').prepend('<strong style="display:block;">References</strong>');
	} else if(count == 0) {
		$('#references').remove();
	} else {
		$('.refBlock').prepend('<strong style="display:block;">Reference</strong>');
	}
}

const mobileMenu = () => {
	$('.hamburger').click(function(){
		$(this).stop().toggleClass('is-active');
		$('#mobileNav').stop().slideToggle();
	});
}

const hcpHeaderMenu = () => {
	const $elem = $('#hcp-dropdown');
	const options = {
		'data-hover-delay': 1,
		'data-closing-time': 1
	}
	const nav = new Foundation.DropdownMenu($elem, options);
}